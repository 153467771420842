import React from 'react';
import { Link } from 'react-router-dom';
import NewBoard from './NewBoard';
import '../styles/Tabs.css';

function NewBoardPage() {
    return (
        <div>
            <NewBoard />
            <div className="tabs bottom-tabs">
                <Link to="/" className="tab-link">
                    <button>Boards</button>
                </Link>
                <Link to="/new-board" className="tab-link">
                    <button className="active">New Board</button>
                </Link>
                <Link to="/settings" className="tab-link">
                    <button>Profile</button>
                </Link>
            </div>
        </div>
    );
}

export default NewBoardPage;