// App.js
import React, { useState, useEffect } from 'react';
import Tabs from './components/Tabs';
import TelegramAuth from './components/TelegramAuth';
import './styles/App.css';

function App() {
    window.Telegram.WebApp.disableVerticalSwipes()
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }

        window.Telegram.WebApp.expand();

    }, []);

    const handleAuth = (user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
    };

    return (
        <div className="app">
            {user ? (
                <Tabs />
            ) : (
                <TelegramAuth onAuth={handleAuth} />
            )}
        </div>
    );
}

export default App;