import React from 'react';

function BidKeyboard({ onBidSelect, onConfirm, selectedBid, isBidDisabled, isValidForX, isValidForXX}) {
    const numbers = ['1', '2', '3', '4', '5', '6', '7'];
    const suits = ['C', 'D', 'H', 'S', 'NT'];
    const actions = ['X', 'XX', 'PASS'];

    const getIcon = (suit) => {
        switch (suit) {
            case 'C':
                return '/icons/clubs.svg';
            case 'D':
                return '/icons/diamonds.svg';
            case 'H':
                return '/icons/hearts.svg';
            case 'S':
                return '/icons/spades.svg';
            default:
                return '';
        }
    };

    const handleNumberClick = (num) => {
        onBidSelect({ type: 'number', value: num });
    };

    const handleSuitClick = (suit) => {
        onBidSelect({ type: 'suit', value: suit });
    };

    const handleActionClick = (action) => {
        onBidSelect({ type: 'action', value: action });
    };

    const isValidBid = () => {
        if ((selectedBid.number && selectedBid.suit) || selectedBid.action ) {
            return true;
        }
        return false;
    };

    return (
        <div className='bid-keyboard'>
            <div className='bid-row'>
                {numbers.map((num) => (
                    <button
                        key={num}
                        onClick={() => handleNumberClick(num)}
                        className={`bid-button ${selectedBid.number === num ? 'selected' : ''}`}
                        disabled={isBidDisabled(num, selectedBid.suit)}
                    >
                        {num}
                    </button>
                ))}
            </div>
            <div className='bid-row'>
                {suits.map((suit) => (
                    <button
                        key={suit}
                        onClick={() => handleSuitClick(suit)}
                        className={`bid-button ${selectedBid.suit === suit ? 'selected' : ''}`}
                        disabled={isBidDisabled(selectedBid.number, suit)}
                    >
                        {suit !== 'NT' ? <img src={getIcon(suit)} alt={`${suit} icon`} className='suit-icon' /> : 'NT'}
                    </button>
                ))}
            </div>
            <div className='bid-row'>
                {actions.map((action) => (
                    <button
                        key={action}
                        onClick={() => handleActionClick(action)}
                        className={`bid-button ${selectedBid.action === action ? 'selected' : ''}`}
                        disabled={action === 'PASS' ? false : action === 'X' ? !isValidForX() : action === 'XX' ? !isValidForXX() : false}
                    >
                        {action}
                    </button>
                ))}
                <button onClick={onConfirm} className='bid-button confirm-button' disabled={!isValidBid()}>
                    Confirm
                </button>
            </div>
        </div>
    );
}

export default BidKeyboard;