// src/components/TelegramAuth.js
import React, { useEffect, useState } from 'react';
import '../styles/TelegramAuth.css';

const TelegramAuth = ({ onAuth }) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null); // State для отображения ошибки

    useEffect(() => {
        const initData = window.Telegram.WebApp;
        if (initData && initData.initDataUnsafe) {
            const userData = initData.initDataUnsafe.user;

            if (!userData.username) {
                setError("Для входа необходим username в Telegram.");
                return; // Не сохраняем и не отправляем запрос
            }

            // Сохраняем данные пользователя в localStorage
            localStorage.setItem("user", JSON.stringify(userData));
            setUser(userData);

            window.onTelegramAuth = async (user) => {
                if (user) {
                    onAuth(user);

                    const payload = {
                        id: user.id,
                        name: user.username
                    };

                    try {
                        const response = await fetch('https://dnevnik.fun/api/user/add', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(payload)
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }

                        const data = await response.json();
                        console.log('Success:', data);
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            };

            window.onTelegramAuth(userData);
        }
    }, [onAuth]);

    return (
        <div className="telegram-auth-container">
            {error ? (
                <p className="error-message">{error}</p> // Сообщение об ошибке
            ) : user ? (
                <p>Logged in as: {user.first_name} {user.last_name}</p>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default TelegramAuth;