import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/DealList.css'

function parse_cards(table, user) {
    if (user.id === table.creatorId) {
        return table.createHand
    }

    return table.partnerHand
}

function sortCards(cards) {
    const order = 'AKQJ1098765432';
    return cards.split('').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join('');
}

function DealList({filter}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const [deals, setDeals] = useState([]);
    let parse_deals = [];

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            axios.get(`https://dnevnik.fun/api/board/id=${user.id}`)
                .then(response => {
                    setDeals(response.data);
                })
                .catch(error => {
                    console.error("There was an error fetching the deals!", error);
                });
        } else {
            console.error("User ID not found in local storage.");
        }
    }, []);

    for (let i = 0; i < deals.length; i++) {
        let opponent = deals[i].creatorId === user.id ? deals[i].partnerName : deals[i].creatorName;

        if (filter === 0 && deals[i].currentPlayerId === user.id && !deals[i].dealFinished) {
            parse_deals.push({
                id: i + 1,
                opponent: opponent,
                dealNumber: deals[i].id,
                cards: parse_cards(deals[i], user)
            });
        } else if (filter === 1 && deals[i].currentPlayerId !== user.id && !deals[i].dealFinished) {
            parse_deals.push({
                id: i + 1,
                opponent: opponent,
                dealNumber: deals[i].id,
                cards: parse_cards(deals[i], user)
            });
        } else if (filter === 2 && deals[i].dealFinished) {
            parse_deals.push({
                id: i + 1,
                opponent: opponent,
                dealNumber: deals[i].id,
                cards: parse_cards(deals[i], user)
            });
        }
    }

    console.log(parse_deals)

    return (
        <div className="deal-list">
            {parse_deals.map(parse_deal => (
                <Link key={parse_deal.id} to={`/deal/${parse_deal.dealNumber}`} className="deal-item">
                    <div className="deal-header">
                        <span>{parse_deal.dealNumber}</span>
                        <span>{parse_deal.opponent}</span>
                    </div>
                    <div className="deal-cards">
                        <div>
                            <img src='/icons/spades.svg' className='icons' alt="spades icon"/>
                            {' ' + sortCards(parse_deal.cards.spades)}
                        </div>
                        <div>
                            <img src='/icons/hearts.svg' className='icons' alt="hearts icon"/>
                            {' ' + sortCards(parse_deal.cards.hearts)}
                        </div>
                        <div>
                            <img src='/icons/diamonds.svg' className='icons' alt="diamonds icon"/>
                            {' ' + sortCards(parse_deal.cards.diamonds)}
                        </div>
                        <div>
                            <img src='/icons/clubs.svg' className='icons' alt="clubs icon" />
                            {' ' + sortCards(parse_deal.cards.clubs)}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

/*
[
    {
        "id": 2,
        "creatorId": 1049537641,
        "partnerId": 1068228162,
        "currentPlayerId": 1049537641,
        "bestContract": "1NT",
        "history": {
            "id": 2,
            "historyItems": []
        },
        "createHand": {
            "id": 5,
            "clubs": "J82",
            "diamonds": "QK",
            "hearts": "AJQ",
            "spades": "AJ328",
            "point": 18
        },
        "partnerHand": {
            "id": 6,
            "clubs": "QA5",
            "diamonds": "AJ",
            "hearts": "K86",
            "spades": "57109",
            "point": 14
        },
        "robotHand1": {
            "id": 7,
            "clubs": "3",
            "diamonds": "653",
            "hearts": "31072",
            "spades": "KQ64",
            "point": 5
        },
        "robotHand2": {
            "id": 8,
            "clubs": "K69104",
            "diamonds": "9824",
            "hearts": "594",
            "spades": "",
            "point": 3
        },
        "dealFinished": false
    }
]

const deals = [
    { id: 1, opponent: 'Player A', dealNumber: 43251, cards: '♠Q J 8 4 ♥A K Q 5 3 ♦9 ♣J 7 6' },
    { id: 2, opponent: 'Player B', dealNumber: 63433, cards: '♠Q J 8 4 ♥A K Q 5 3 ♦9 ♣J 7 6' },
    { id: 3, opponent: 'Player C', dealNumber: 12345, cards: '♠A K 10 7 ♥Q J 9 6 ♦8 5 ♣K Q 4' },
    { id: 4, opponent: 'Player D', dealNumber: 67890, cards: '♠J 9 6 3 ♥K Q 10 8 ♦A 7 4 ♣Q J 5' },
    { id: 5, opponent: 'Player E', dealNumber: 54321, cards: '♠K Q 7 5 ♥J 10 9 4 ♦A 6 3 ♣K 8 2' },
    { id: 6, opponent: 'Player F', dealNumber: 98765, cards: '♠A J 8 6 ♥K Q 9 5 ♦10 7 4 ♣J 3 2' },
    { id: 7, opponent: 'Player G', dealNumber: 11223, cards: '♠Q 10 9 4 ♥A K J 7 ♦8 6 5 ♣Q 3 2' },
    { id: 8, opponent: 'Player H', dealNumber: 44556, cards: '♠K J 8 5 ♥Q 10 9 6 ♦A 7 3 ♣K 4 2' },
    { id: 9, opponent: 'Player I', dealNumber: 77889, cards: '♠A Q 10 7 ♥K J 9 5 ♦8 6 4 ♣J 3 2' },
    { id: 10, opponent: 'Player J', dealNumber: 99001, cards: '♠K J 9 6 ♥Q 10 8 5 ♦A 7 4 ♣K 3 2' },
    { id: 11, opponent: 'Player K', dealNumber: 22334, cards: '♠A K Q 10 ♥J 9 8 6 ♦7 5 4 ♣Q 3' },
    { id: 12, opponent: 'Player L', dealNumber: 55667, cards: '♠Q J 9 7 ♥K 10 8 5 ♦A 6 4 ♣J 3' },
    { id: 13, opponent: 'Player M', dealNumber: 88990, cards: '♠K Q 10 6 ♥J 9 8 4 ♦A 7 3 ♣K 2' },
    { id: 14, opponent: 'Player N', dealNumber: 11234, cards: '♠A J 9 5 ♥K Q 10 7 ♦8 6 4 ♣J 3' },
    { id: 15, opponent: 'Player O', dealNumber: 33445, cards: '♠Q 10 8 6 ♥A K J 5 ♦9 7 4 ♣Q 3' },
    { id: 16, opponent: 'Player P', dealNumber: 66778, cards: '♠K J 9 4 ♥Q 10 8 6 ♦A 7 3 ♣K 2' },
    { id: 17, opponent: 'Player Q', dealNumber: 99012, cards: '♠A Q 10 7 ♥K J 9 5 ♦8 6 4 ♣J 3' },
    { id: 18, opponent: 'Player R', dealNumber: 12345, cards: '♠K J 8 5 ♥Q 10 9 6 ♦A 7 3 ♣K 4' },
    { id: 19, opponent: 'Player S', dealNumber: 45678, cards: '♠A K Q 10 ♥J 9 8 6 ♦7 5 4 ♣Q 3' },
    { id: 20, opponent: 'Player T', dealNumber: 78901, cards: '♠Q J 9 7 ♥K 10 8 5 ♦A 6 4 ♣J 3' },
];
*/

export default DealList;