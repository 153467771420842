import React from 'react';
import DealList from './DealList';

function MyTurn() {
    return (
        <div>
            <DealList filter={0}/>
        </div>
    );
}

export default MyTurn;