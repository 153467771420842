// src/components/DealDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../styles/DealDetail.css"
import { MdArrowBackIos } from "react-icons/md";
import { PiDotsThreeOutline } from "react-icons/pi";
import BidKeyboard from './BidKeyboard';


const sendNotification = async (userId, boardId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const response = await fetch('https://api.telegram.org/bot7344166368:AAF7IS1pW_b8-9KSyvCImKzmYzaZhXbLgRw/sendMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chat_id: userId,
                text: user.username + ' ответил на сдачу № ' + boardId,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Notification sent:', data);
    } catch (error) {
        console.error('Error sending notification:', error);
    }
};

function parse_cards(table, user) {
    if (user.id === table.creatorId) {
        return table.createHand
    }

    return table.partnerHand
}

function sortCards(cards) {
    const order = 'AKQJ1098765432';
    return cards.split('').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join('');
}

function getIcon(bid) {
    switch (bid) {
        case "C":
            return "/icons/clubs.svg"
        case "H":
            return "/icons/hearts.svg"
        case "D":
            return "/icons/diamonds.svg"
        case "S":
            return "/icons/spades.svg"
    }
}

function DealDetail() {
    const { id } = useParams(); // Retrieve the deal ID from the route parameter
    const [dealDetail, setDealDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBid, setSelectedBid] = useState({ number: '', suit: '', action: '' });
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const fetchDealDetails = async () => {
            try {
                const response = await axios.get(`https://dnevnik.fun/api/board/boardId=${id}?userId=${user.id}`);
                setDealDetail(response.data);
            } catch (err) {
                setError("There was an error loading the deal details.");
            } finally {
                setLoading(false);
            }
        };
    
        fetchDealDetails();
    
        const interval = setInterval(fetchDealDetails, 5000);
    
        return () => clearInterval(interval);
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    let p_cards = parse_cards(dealDetail, user)

    const historyRows = [];
    if (dealDetail.history && dealDetail.history.historyItems) {
        let fl = false;
        for (let i = 0; i < dealDetail.history.historyItems.length; i += 4) {
            let row = [];
            if (dealDetail.history.historyItems[0].player === "robot-1" && !fl) {
                row = ["", ...dealDetail.history.historyItems.slice(i, i + 3)];
                i--;
                fl = true;
            }
            else if (dealDetail.history.historyItems[0].player === "robot-2" && !fl) {
                const historyItems = dealDetail.history.historyItems;
                const historyItemsSlice = [historyItems[0]];
                row = ["", "", "", ...historyItemsSlice];
                i = i - 3;
                fl = true;
            }
            else if  (dealDetail.history.historyItems[0].player == dealDetail.eastPlayerId && !fl) {
                row = ["", "", ...dealDetail.history.historyItems.slice(i, i + 2)];
                console.log("alskdmalskdm")
                i = i - 2;
                fl = true;
            }
            else {
                row = dealDetail.history.historyItems.slice(i, i + 4);
            }
            
            let hasEllipsis = false;

            if (row.length == 4) {
                historyRows.push(row);
            }
            else {
                while (row.length < 4) {
                    if (!hasEllipsis) {
                        row.push({ bid: '...' }); // First empty item as ellipsis icon
                        hasEllipsis = true;
                    } else {
                        row.push({ bid: '' }); // Fill with empty objects
                    }
                }
                historyRows.push(row);
            }
            if (i === dealDetail.history.historyItems.length -4) {
                row = []

                while (row.length < 4) {
                    if (!hasEllipsis) {
                        row.push({ bid: '...' }); // First empty item as ellipsis icon
                        hasEllipsis = true;
                    } else {
                        row.push({ bid: '' }); // Fill with empty objects
                    }
                }
                historyRows.push(row);
            }
        }
    }

    const getLastBidValue = () => {
        if (!dealDetail.history || !dealDetail.history.historyItems.length) return null;

        for (let i = dealDetail.history.historyItems.length - 1; i >= 0; i--) {
            const lastBid = dealDetail.history.historyItems[i].bid;
            if (lastBid !== 'PASS' && lastBid !== 'X' && lastBid !== 'XX') {
                return lastBid;
            }
        }

        return null;
    };

    const isBidDisabled = (number, suit) => {
        console.log('isBidDisabled called with:', { number, suit });
        const lastBid = getLastBidValue();
        console.log('lastBid:', lastBid);
        if (!lastBid) return false;

        const lastBidNumber = parseInt(lastBid[0]);
        const lastBidSuit = lastBid.slice(1);
        const currentBidNumber = parseInt(number);
        const suitOrder = ['C', 'D', 'H', 'S', 'NT'];

        console.log('lastBidNumber:', lastBidNumber, 'lastBidSuit:', lastBidSuit);
        console.log('currentBidNumber:', currentBidNumber, 'suit:', suit);

        if (!suit) {
            // Check if all suits for the current number are disabled
            const allSuitsDisabled = suitOrder.every(s => isBidDisabled(number, s));
            console.log('All suits disabled for number', number, ':', allSuitsDisabled);
            return allSuitsDisabled;
        }
        if (currentBidNumber < lastBidNumber) {
            console.log('Current bid number is less than last bid number, returning true');
            return true;
        }
        if (currentBidNumber === lastBidNumber && suitOrder.indexOf(suit) <= suitOrder.indexOf(lastBidSuit)) {
            console.log('Current bid number is equal to last bid number and suit is less than or equal to last bid suit, returning true');
            return true;
        }
        console.log('Returning false');
        return false;
    };

    const isValidForX = () => {
        if (!dealDetail.history || !dealDetail.history.historyItems.length) return false;

        for (let i = dealDetail.history.historyItems.length - 1; i >= 0; i--) {
            const lastBid = dealDetail.history.historyItems[i];
            if (lastBid.bid !== 'X' && lastBid.bid !== 'XX' && lastBid.bid !== 'PASS' && (lastBid.player === 'robot-1' || lastBid.player === 'robot-2')) {
                return true;
            }
        }
    }

    const isValidForXX = () => {
        if (!dealDetail.history || !dealDetail.history.historyItems.length) return false;

        for (let i = dealDetail.history.historyItems.length - 1; i >= 0; i--) {
            const lastBid = dealDetail.history.historyItems[i];
            if (lastBid.bid === 'X' && (lastBid.player === 'robot-1' || lastBid.player === 'robot-2')) {
                return true;
            }
        }
    }

    const handleBidSelect = (bid) => {
        if (bid.type === 'number') {
            setSelectedBid({ number: bid.value, suit: '', action: '' });
        } else if (bid.type === 'suit') {
            setSelectedBid((prev) => ({ ...prev, suit: bid.value, action: '' }));
        } else if (bid.type === 'action') {
            setSelectedBid({ number: '', suit: '', action: bid.value });
        }
    };

    const handleConfirm = async () => {
        const bid = selectedBid.number + selectedBid.suit + selectedBid.action;
        const applicationRequest = {
            boardId: id,
            bid: bid,
            userId: user.id
        };

        try {
            const response = await axios.post('https://dnevnik.fun/api/board/makeAnApplication', applicationRequest);
            console.log('Application successful:', response.data);
            setDealDetail(response.data);

            // Send notification to partner
            const partnerId = user.id === dealDetail.creatorId ? dealDetail.partnerId : dealDetail.creatorId;
            await sendNotification(partnerId, dealDetail.id);
        } catch (error) {
            console.error('Error making application:', error);
            window.location.reload();
        }

        setSelectedBid({ number: '', suit: '', action: '' }); // Reset selected bid
    };

    return (
        <React.Fragment>
            <div className='screen-container'>
                <div className='top-bar'>
                    <MdArrowBackIos size={50} className='back-icon' onClick={() => window.history.back()} />
                    <p className='id'>{"#" + dealDetail.id}</p>
                </div>
                <div className='scrollable-container'>
                    <div className='cards-block'>
                        <div className='card-inner-container' style={{marginLeft: user.id === dealDetail.westPlayerId ? "5%" : "54%"}}>
                            <p className='position-text'>{user.id === dealDetail.westPlayerId ? "West" : "East"}</p>
                            <div>
                                <div>
                                    <img src='/icons/spades.svg' className='icons' alt="spades icon" />
                                    {' ' + sortCards(p_cards.spades)}
                                </div>
                                <div>
                                    <img src='/icons/hearts.svg' className='icons' alt="hearts icon" />
                                    {' ' + sortCards(p_cards.hearts)}
                                </div>
                                <div>
                                    <img src='/icons/diamonds.svg' className='icons' alt="diamonds icon" />
                                    {' ' + sortCards(p_cards.diamonds)}
                                </div>
                                <div>
                                    <img src='/icons/clubs.svg' className='icons' alt="clubs icon" />
                                    {' ' + sortCards(p_cards.clubs)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bid-block'>
                        <div className='headers-block'>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <p className='position-text'>West</p>
                                <p className='position-text' style={{fontSize: "20px", color: "#304065"}}>
                                    {dealDetail.westPlayerId === dealDetail.creatorId ? dealDetail.creatorName : dealDetail.partnerName}
                                </p>
                            </div>
                            <p className='position-text'>North</p>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <p className='position-text'>East</p>
                                <p className='position-text' style={{fontSize: "20px", color: "#304065"}}>
                                {dealDetail.eastPlayerId === dealDetail.creatorId ? dealDetail.creatorName : dealDetail.partnerName}
                                </p>
                            </div>
                            <p className='position-text'>South</p>
                        </div>
                        <div className='history-block'>
                            {historyRows.map((row, rowIndex) => (
                                <div key={rowIndex} className='history-row'>
                                    {row.map((item, itemIndex) => (
                                        <div key={itemIndex} className={`history-item ${!item.bid ? 'empty-item' : ''}`}>
                                            <p className='position-text'
                                            style={{fontSize: "25px", display: "flex", alignItems: "center"}}>
                                                {item.bid ? (
                                                    item.bid === "PASS" ? "PASS" :
                                                    item.bid[0] === "X" ? item.bid :
                                                        item.bid === '...' ? (
                                                            <PiDotsThreeOutline  size={40} color={"#515262"}/>
                                                        ) : (
                                                            <>
                                                                {item.bid[0]}
                                                                {item.bid[1] !== "N" ?
                                                                    <img style={{width: "25px"}} src={getIcon(item.bid[1])}
                                                                        alt="bid icon"/> : item.bid[1] + item.bid[2]}
                                                            </>
                                                        )
                                                ) : null}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {dealDetail.currentPlayerId === user.id && !dealDetail.dealFinished && (
                    <BidKeyboard
                        onBidSelect={handleBidSelect}
                        onConfirm={handleConfirm}
                        selectedBid={selectedBid}
                        isBidDisabled={isBidDisabled}
                        getLastBidValue={getLastBidValue}
                        isValidForX={isValidForX}
                        isValidForXX={isValidForXX}
                    />
                )}
            </div>  
        </React.Fragment>
    );
}

export default DealDetail;