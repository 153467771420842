import React from 'react';
import DealList from "./DealList";

function Finished() {
    return (
        <div>
            <DealList filter={2}/>
        </div>
    );
}

export default Finished;