import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import NewBoardPage from "./pages/NewBoardPage";
import SettingsPage from "./pages/SettingsPage"; // Пример другой страницы
import DealDetail from "./components/DealDetail";

function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/new-board" element={<NewBoardPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/deal/:id" element={<DealDetail />} />
            </Routes>
        </Router>
    );
}

export default AppRoutes;