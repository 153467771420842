import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../styles/NewBoard.css"

function NewBoard() {
    const [partnerId, setPartnerId] = useState('');
    const [countPoints, setCountPoints] = useState('');
    const [recentIds, setRecentIds] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const storedIds = JSON.parse(localStorage.getItem('recentIds')) || [];
        setRecentIds(storedIds);
    }, []);

    const handleCreateBoard = async (randomCountPoints = null) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.id) {
            console.error('User ID not found in localStorage');
            return;
        }

        const creatorId = user.id;
        const newId = partnerId;

        if (!newId) {
            setErrorMessage('Partner ID must be provided.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
            return;
        }

        if (newId && !recentIds.includes(newId)) {
            const updatedIds = [...recentIds, newId];
            setRecentIds(updatedIds);
            localStorage.setItem('recentIds', JSON.stringify(updatedIds));
        }

        let points = randomCountPoints !== null ? randomCountPoints : parseInt(countPoints, 10);
        if (isNaN(points) || points < 0 || points > 36) {
            setErrorMessage('Count Points must be a number between 0 and 36.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
            return;
        }

        const requestData = {
            createId: creatorId,
            partner: partnerId,
            countPoints: points,
        };

        try {
            const response = await axios.post('https://dnevnik.fun/api/board/create', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log(response.data);
            setSuccessMessage('Board created successfully!');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            console.error('Error creating board:', error);
        }

        setPartnerId('');
        setCountPoints('');
    };

    const handleRandomPoints = () => {
        const randomPoints = Math.floor(Math.random() * 37);
        handleCreateBoard(randomPoints);
    };

    const handlePointsChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setCountPoints(value);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <input
                type="text"
                value={partnerId}
                onChange={(e) => setPartnerId(e.target.value)}
                placeholder="Partner Username"
            />
            <div style={{ display: 'flex', alignItems: 'center', width: '217px',  justifyContent: "space-around", marginBottom: "10px"}}>
                <input
                    type="number"
                    value={countPoints}
                    onChange={handlePointsChange}
                    placeholder="Enter Count Points"
                    min="0"
                    max="36"
                    style={{width: "100%", marginBottom: "0"}}
                />
                <button onClick={handleRandomPoints} style={{ marginLeft: '10px' }} className='rnd'>
                    <img src='/icons/dice.svg' className='dice'></img>
                </button>
            </div>
            <button onClick={() => handleCreateBoard()}>Create</button>
            {successMessage && <div style={{ color: 'green', marginTop: '10px' }}>{successMessage}</div>}
            {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
            <div>
                <h3>Recent Partner IDs:</h3>
                <div>
                    {recentIds.map((id, index) => (
                        <button key={index} onClick={() => setPartnerId(id)} style={{ cursor: 'pointer', margin: '5px' }}>
                            {id}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewBoard;