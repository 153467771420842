import React, { useEffect, useState } from 'react';
import '../styles/Settings.css';
 
function Settings() {
    const [user, setUser] = useState(null);
    const [otherUserId, setOtherUserId] = useState('');

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const sendNotification = async (userId) => {
        try {
            const response = await fetch('https://api.telegram.org/bot7344166368:AAF7IS1pW_b8-9KSyvCImKzmYzaZhXbLgRw/sendMessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: userId,
                    text: 'ВАМ ОТВЕТИЛ ' + user.username,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Notification sent:', data);
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        setUser(null);
        window.location.href = '/';
    };

    return (
        <div className="settings-content">
            <div className='user-info-block'>
                {user ? (
                    <div className="user-info">
                        <img src={user.photo_url}></img>
                        {user.username && <p>Username: @{user.username}</p>}
                        <p>ID: {user.id}</p>
                    </div>
                ) : (
                    <p>No user data available</p>
                )}
            </div>
        </div>
    );
}

export default Settings;